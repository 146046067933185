import React from "react";
import styled from "styled-components";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import { MDXProvider } from "@mdx-js/react";
import ReactMarkdown from "react-markdown";

import PageTemplate from "./Page";

const BlogPostTemplate = ({ data, location }) => {
  const post = data.contentfulBlogPost;
  const siteTitle = data.site.siteMetadata.title;
  const postBody = data.contentfulBlogPost.body.internal.content;

  return (
    <MDXProvider>
      <PageTemplate>
        <PageWrapper>
          <div style={{ background: "#F5F5F4" }}>
            <Helmet title={`${post.title} | ${siteTitle}`} />
            <Post>
              <h1 className="section-headline">{post.title}</h1>
              <p
                style={{
                  display: "block",
                }}
              >
                {post.publishDate}
              </p>
              {/* <MDXRenderer>{postBody}</MDXRenderer> */}
              <ReactMarkdown>{postBody}</ReactMarkdown>
            </Post>
          </div>
        </PageWrapper>
      </PageTemplate>
    </MDXProvider>
  );
};

export default BlogPostTemplate;

const Post = styled.div`
  max-width: 680px;
  margin: 0 auto;
  margin-top: 3rem;

  h1 {
    font-size: 2.3em;
    font-weight: 400;
  }
  h2 {
    font-size: 1.4em;
    font-weight: 400;
  }
  h3 {
    font-size: 1.3em;
    font-weight: 400;
  }
  h4 {
    font-size: 1.2em;
    font-weight: 400;
  }

  img {
    width: 100%;
  }
`;

const PageWrapper = styled.div`
  .hero {
    position: relative;
    background: #000;
    color: #fff;
    text-align: center;
  }

  .heroImage {
    /*
    Ensure golden ratio for the hero size while limiting it to some extend to
    the viewport width
  */
    height: 61.8vh;
    max-height: 400px;
  }

  .heroDetails {
    position: absolute;
    background: rgba(0, 0, 0, 0.7);
    left: 50%;
    bottom: 0;
    transform: translate(-50%, 0);
    font-size: 14px;
    padding: 0 0.5em;
  }

  @media (min-width: 600px) {
    .heroDetails {
      font-size: 16px;
    }
  }

  @media (min-width: 1000px) {
    .heroDetails {
      font-size: 20px;
    }
  }

  .heroHeadline {
    margin: 0;
  }

  .heroTitle {
    margin: 0;
    font-size: 1.125em;
    font-weight: bold;
  }
`;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulBlogPost(slug: { eq: $slug }) {
      title
      publishDate(formatString: "MMMM Do, YYYY")
      heroImage {
        file {
          url
        }
      }
      body {
        internal {
          content
        }
      }
    }
  }
`;
